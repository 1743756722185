<template>
	<v-switch-case :value="type">
		<template #checkbox>
			<v-checkbox
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:input-value="value"
				@change="input"
				false-value=""
				true-value="1"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
				class="mt-0"
			></v-checkbox>
		</template>

		<template #toggle>
			<v-switch
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:input-value="value"
				@change="input"
				false-value=""
				true-value="1"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			></v-switch>
		</template>

		<template #yesno>
			<v-radio-group
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@change="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
				row
			>
				<v-radio label="Да" value="1"></v-radio>
				<v-radio label="Не" value="0"></v-radio>
				<v-icon v-if="value" @click="input('')">mdi-close</v-icon>
			</v-radio-group>
		</template>

		<template #btn-toggle>
			<v-btn-toggle
				v-bind="$attrs"
				:color="$attrs.color ? $attrs.color : 'primary'"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@change="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
				row
			>
				<v-btn
					v-for="n in items"
					:key="n.value"
					:value="n.value"
					:active-class="n.class"
				>{{n.text}}</v-btn>
			</v-btn-toggle>
		</template>

		<template #radiogroup>
			<v-radio-group
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@change="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			>
				<v-radio
					v-for="n in items"
					:key="n.value"
					:label="n.text"
					:value="n.value"
				></v-radio>
			</v-radio-group>
		</template>

		<template #select>
			<v-select
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@change="input"
				:items="items"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
					><slot :name="slot" v-bind="scope"
				/></template>
			</v-select>
		</template>

		<template #select-multiple>
			<v-select
				v-bind="$attrs"
				:hide-details="hideDetails"
				multiple
				:rules="proxyRules"
				:value="value"
				@change="input"
				:items="items"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
					><slot :name="slot" v-bind="scope"
				/></template>
			</v-select>
		</template>

		<template #checkboxgroup>
			<v-input
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				row
				class="cbgroup"
			>
				<template #default>
					<v-checkbox
						:disabled="$attrs.disabled"
						multiple
						v-for="(n, i) in items"
						:key="i"
						:input-value="value"
						@change="input"
						:label="n.text"
						:value="n.value"
						hide-details
					/>
				</template>
			</v-input>
		</template>

		<template #date>
			<DateInput
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			></DateInput>
		</template>

		<template #textarea>
			<v-textarea
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			/>
		</template>

		<template #managedfiles>
			<ManagedFiles
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			/>
		</template>

		<template #password>
			<v-text-field
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:type="showPassword ? 'text' : 'password'"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
				:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="showPassword = !showPassword"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
					><slot :name="slot" v-bind="scope"
				/></template>
			</v-text-field>
		</template>

		<template #decimal>
			<decimal-input
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:type="defaultInputType"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
				:inputmode="$attrs['inputmode'] || 'decimal'"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
				><slot :name="slot" v-bind="scope"
				/></template>
			</decimal-input>
		</template>

		<template #combobox>
			<v-combobox
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@change="input"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
					><slot :name="slot" v-bind="scope"
				/></template>
			</v-combobox>
		</template>

		<template #default>
			<v-text-field
				v-bind="$attrs"
				:hide-details="hideDetails"
				:rules="proxyRules"
				:value="value"
				@input="input"
				:type="defaultInputType"
				:error="$data._errors.length > 0"
				:error-messages="$data._errors"
				:error-count="$data._errors.length || 1"
			>
				<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
					><slot :name="slot" v-bind="scope"
				/></template>
			</v-text-field>
		</template>
	</v-switch-case>
</template>

<script>
import DateInput from "./DateInput";
import ManagedFiles from "./ManagedFiles";
import Validators from "../Validators";
import VSwitchCase from "../components/vSwitchCase.vue";
import {itemsForSelect} from "@/ittijs/utils";
import DecimalInput from "@/ittijs/Inputs/DecimalInput";

export default {
	inheritAttrs: false,

	inject: {
		inputGroups: {
			default: null,
		},
	},

	components: {
		DecimalInput,
		DateInput,
		ManagedFiles,
		VSwitchCase,
	},

	data: ()=>({
		_errors: [],
		showPassword: false,
	}),

	props: {
		type: {
			type: String,
			default: '',
		},
		value: {},
		options: {}, // for radiogroup, checkboxgroup, select, multiselect
		rules: {
			type: Array,
			default: ()=>[],
		},
		errors: {
			type: Array,
			default: ()=>[],
		},
		hideDetails: {
			default: "auto",
		},
	},

	watch: {
		errors(to) {
			this.$data._errors = to;
		},
		items(val) {
			// check if the new list contains the value, and if not - reset the value
			if (this.value===null) return; // no value, no need to check
			if (!Array.isArray(val)) return;
			if (!val.length) return; // empty list - maybe still waiting to load?
			if (Array.isArray(this.value)) {
				this.$emit('input',
					this.value.filter(v => val.some(elem => elem.value == v))
				);
			}
			else {
				if (!val.some(elem => elem.value == this.value)) {
					this.$emit('input', null);
				}
			}
		},
	},

	computed: {
		proxyRules() {
			if (this.$attrs.disabled || this.$attrs.readonly) {
				return [];
			}

			const rules = [...this.rules];

			switch (this.type) {
				case 'email':
					rules.push(Validators.email());
					break;
			}

			const attr2type = {
				required: true, // all
				minstring: true,
				maxstring: true,
				minlength: true,
				maxlength: true,
				mincount: ['select','checkboxgroup'],
				maxcount: ['select','checkboxgroup'],
				min: ['number', 'decimal'],
				max: ['number', 'decimal'],
				pattern: true,
			};

			const attr2val = {
				required: ()=>Validators.required(),
				minstring: ()=>Validators.minString(this.$attrs.minstring),
				maxstring: ()=>Validators.maxString(this.$attrs.maxstring),
				minlength: ()=>Validators.minLength(this.$attrs.minlength),
				maxlength: ()=>Validators.maxLength(this.$attrs.maxlength),
				mincount: ()=>Validators.minCount(this.$attrs.mincount),
				maxcount: ()=>Validators.maxCount(this.$attrs.maxcount),
				min: ()=>Validators.min(this.$attrs.min),
				max: ()=>Validators.max(this.$attrs.max),
				pattern: ()=>Validators.pattern(new RegExp(this.$attrs.pattern)),
			};

			for (const attr in attr2type) {
				if (typeof(this.$attrs[attr])==='undefined') continue;
				let add = false;
				if (true===attr2type[attr]) {
					add = true;
				}
				else if (Array.isArray(attr2type[attr]) && 0 <= attr2type[attr].indexOf(this.type)) {
					add = true;
				}
				else if (typeof attr2type[attr] === 'function' && attr2type[attr]()) {
					add = true;
				}
				if (add) {
					rules.push(attr2val[attr]());
					delete this.$attrs[attr];
				}
			}

			// int, dec validator cannot be used above because they work together, handle them separately
			if (this.type==='decimal') {
				const int = parseInt(this.$attrs.int);
				let dec = parseInt(this.$attrs.dec);
				if (isNaN(dec)) dec = 0;
				if (!isNaN(int) && int >=0) {
					rules.push(Validators.decimal(int, dec));
				}
				delete this.$attrs.int;
				delete this.$attrs.dec;
			}

			return rules;
		},
		defaultInputType(){
			// translate from this component's type prop to the type of the underlying input
			if (this.type==='decimal') {
				return 'text';
			}
			return this.type;
		},
		items() {
			return itemsForSelect(this.options);
		},
	},

	created () {
		this.inputGroups && this.inputGroups.regInput('base', this);
	},

	beforeDestroy () {
		this.inputGroups && this.inputGroups.unregInput('base', this);
	},

	methods: {
		input(v) {
			this.$data._errors = [];
			this.$emit("input", v);
		},
	},
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
::v-deep input[type=number] {
	-moz-appearance: textfield;
}
.cbgroup {
	margin-top: 16px;
	padding-top: 4px;
}
.cbgroup::v-deep > .v-input__control > .v-input__slot {
	flex-direction: column;
	align-items: flex-start;
	color: green;
}
.cbgroup::v-deep > .v-input__control > .v-input__slot > .v-label {
	padding-bottom: 8px;
	font-size: 14px;
	height: auto;
}
.cbgroup::v-deep > .v-input__control > .v-input__slot > .v-input--checkbox {
	margin-top: 0;
	margin-bottom: 8px;
	padding-top: 0;
	padding-bottom: 0;
}
</style>