<template>
  <input
    type="file"
    ref="input"
    :multiple="multiple"
    :accept="accept"
    v-show="false"
    @change="onChange"
  />
</template>
<script>
export default {
  props: {
    multiple: Boolean,
    accept: String,
  },
  data(){
    return {
      isOpen: false,
    };
  },
  methods: {
    open(){
      this.$refs.input.value = '';
      this.isOpen = true;
      this.$refs.input.click();
    },
    onChange(event){
      event.preventDefault();
      event.stopPropagation();
      if (this.isOpen) {
        this.isOpen = false;
        this.$emit('selected', event.target.files);
        event.target.value = '';
      }
    },
  },
}
</script>