<template>
	<v-sheet :outlined="$attrs.outlined===false ? false : true" rounded>
		<v-input v-bind="$attrs">
			<draggable v-model="files" draggable=".item">

				<v-chip
					v-for="(file, index) in files"
					:key="file.id"
					:close="!(disabled||readonly)"
					:class="{item:!(disabled||readonly),'ma-1':true}"
					:link="!!file.url"
					:href="file.url"
					target="_blank"
					label
					@click="onFileClick(file)"
					@click:close="onRemoveFileClick(index)"
				>
					<v-icon left>{{ fileToMaterialIcon(file.name, file.type) || "mdi-file" }}</v-icon>
					{{ file.name }} ({{ formatBytes(file.size) }})
					<v-img
						v-if="file.thumb"
						:src="file.thumb"
						width="60"
						class="rounded ml-1"
					/>
				</v-chip>

				<v-chip
					slot="footer"
					v-if="!(disabled||readonly)"
					class="ma-1"
					@click="onAddClick"
					label
				>
					<v-icon>mdi-upload</v-icon>
				</v-chip>

				<file-select
					:multiple="multiple"
					:accept="accept"
					ref="fileDialog"
					@selected="onUploadFilesSelected"
				/>

			</draggable>
		</v-input>

		<v-alert type="warning" outlined text dense class="ma-2" v-if="warnFilesizeTotalInt && fileSizeTotal > warnFilesizeTotalInt">
			Total file size ({{formatBytes(fileSizeTotal)}}) is larger than {{warnFilesizeTotal}}
		</v-alert>

		<div v-if="uploadQueue.length">
			<div v-for="item in uploadQueue" :key="item.key">
				<v-container>
					<v-row v-if="item.count == 1" align="end">
						{{ item.files.name }} ({{ formatBytes(item.size) }})
						<v-spacer/>
						<v-btn dense right @click="onAbortClick(item)" color="red lighten-1">Abort</v-btn>
					</v-row>
					<v-row v-else align="end">
						{{ item.count }} files ({{ formatBytes(item.size) }})
						<v-spacer/>
						<v-btn dense right @click="onAbortClick(item)" color="red lighten-1">Abort</v-btn>
					</v-row>
					<v-row class="mt-2">
						<v-progress-linear rounded height="24" :value="Math.floor(item.progress.progress * 100)">
							<v-container>
								<v-row justify="space-between">
									<v-col cols="4">{{ formatBytes(item.progress.speed) }}/s</v-col>
									<v-col cols="4" class="text-center font-weight-bold">{{ (item.progress.progress * 100).toFixed(1) }} %</v-col>
									<v-col cols="4" class="text-right"><span v-if="isFinite(item.progress.timeRemaining)">{{ item.progress.timeRemaining.toFixed(1) }}s</span></v-col>
								</v-row>
							</v-container>
						</v-progress-linear>
					</v-row>
				</v-container>
			</div>
		</div>
	</v-sheet>
</template>
<script>
import draggable from "vuedraggable";
import IModelMixin from '../IModelMixin';
import FileSelect from './FileSelect.vue';
import {formatBytes, ProgressSpeed, fileToMaterialIcon, parseBytes} from '@/ittijs/utils';

export default {
	mixins: [
		IModelMixin,
	],
	components: {
		draggable,
		FileSelect,
	},
	data() {
		return {
			files: [],
			uploadQueue: [],
			uploadQueueCurrent: null, // the currently uploading item from the queue
			uploadQueueKey: 0, // unique key for the upload queue items
			getInfoQueue: [], // array of file ids
			loading: false,
		};
	},
	props: {
		value: String,
		image: Boolean,
		multiple: Boolean,
		disabled: Boolean,
		readonly: Boolean,
		accept: String,
		// reject: {
		//   type:String,
		//   default:".php"
		// },
		label: String,
		// can be provided an object with file infos to use instead of asking the model
		fileinfo: {
			type: Object,
			default: ()=>({}),
		},
		warnFilesizeSingle: [Number,String],
		warnFilesizeTotal: [Number,String],
	},
	computed: {
		fileSizeTotal(){
			return this.files.reduce((sum,v) => sum + v.size, 0);
		},
		warnFilesizeSingleInt(){
			return parseBytes(this.warnFilesizeSingle);
		},
		warnFilesizeTotalInt(){
			return parseBytes(this.warnFilesizeTotal);
		},
	},
	watch: {
		files(val){
			this.$emit('input', val.map(f=>f.id).join(','));
		},
		value: {
			handler(to){
				if (to == this.files.map(e=>e.id).join(',')) return; // files are the same, do not rebuild
				let ids = to && this.explodeIDs(to) || [];
				if (!this.multiple && ids.length > 1) {
					ids = [ids[0]];
				}
				this.files = ids.map(id=>{
					if (this.fileinfo[id]) {
						return {id, ...this.fileinfo[id]};
					}
					else {
						this.getInfoQueue.push(id);
						return {id};
					}
				});
				this.processInfoQueue();
			},
			immediate: true,
		},
	},
	methods: {
		formatBytes,
		fileToMaterialIcon,
		explodeIDs(idList){
			return idList.split(',').filter(s => s!=='');
		},
		onFileClick(file) {
			console.log('file', { ...file });
		},
		onRemoveFileClick(index) {
			// todo - delete file from server
			this.files.splice(index, 1);
		},
		onAddClick() {
			this.$refs.fileDialog.open();
		},
		onUploadFilesSelected(files){
			for (const file of files) {
				if (this.warnFilesizeSingleInt > 0 && file.size > this.warnFilesizeSingleInt) {
					if (!confirm(`${file.name} is larger than ${this.warnFilesizeSingle} (${formatBytes(file.size)}). Please confirm!`)) {
						continue;
					}
				}
				this.pushToUploadQueue(file);
			}
			this.uploadQueueStartNext();
			// alternative:
			// this.pushToUploadQueue(files);
		},
		onAbortClick(item){
			this.uploadQueue = this.uploadQueue.filter(v => v!==item);
			if (this.uploadQueueCurrent === item) {
				item.handle.abort();
				this.uploadQueueCurrent = null;
			}
			this.uploadQueueStartNext();
		},
		processInfoQueue(){
			this.imodel.mfInfo(this.getInfoQueue).then(info=>{
				for (const id in info) {
					const finfo = info[id];
					for (const i in this.files) {
						if (id == this.files[i].id && !this.files[i].name) {
							// preserve reactivity, don't assign to this.files[i]
							this.$set(this.files, i, finfo);
						}
					}
				}
			});
		},
		pushToUploadQueue(files){
			const queueItem = {
				key: this.uploadQueueKey++,
				files,
				count: 1,
				progress: new ProgressSpeed(5),
			};
			if (files instanceof FileList && files.length===1) {
				// convert a list of 1 file to just the file
				files = files[0];
			}
			if (files instanceof File) {
				queueItem.size = files.size;
			}
			else if (files instanceof FileList) {
				queueItem.count = files.length;
				queueItem.size = Array.from(files).reduce((total, file) => total + file.size, 0);
			}
			else {
				return;
			}
			this.uploadQueue.push(queueItem);
		},
		uploadQueueStartNext(){
			if (this.uploadQueue.length > 0 && this.uploadQueueCurrent === null) {
				const item = this.uploadQueueCurrent = this.uploadQueue[0];
				item.handle = this.imodel.mfUpload(item.files, item.progress.push.bind(item.progress));
				item.handle.promise.then(r=>{
					this.uploadQueue = this.uploadQueue.filter(v => v!==item);
					for (const f of r) {
						this.files.push(f);
					}
					this.uploadQueueCurrent = null;
					this.uploadQueueStartNext();
				});
			}
		},
	},
};
</script>