import _ from "lodash";

/**
 * Replace message params with values. Params in the message look like this: {param}
 * @param msg
 * @param params
 * @return {*}
 */
const replace = (msg, params) => {
	if (params && typeof params === 'object') {
		for (const p in params) {
			msg = msg.replace('{'+p+'}', params[p]);
		}
	}
	return msg;
}

/**
 * Takes a message template (string) and returns a callback function.
 * This function accepts params argument, replaces those params in the message,
 * and returns the resulting string.
 */
const mkMsgFn = str => params => replace(str, params);

export default {

	/**
	 * Each item is a function that accepts parameters and replaces them in the message
	 */
	messages: {
		required:        mkMsgFn("Задължително"),
		email:           mkMsgFn("Невалиден имейл"),
		decimal:         mkMsgFn("Невалидно число {int}d.{dec}d"),
		pattern:         mkMsgFn("Невалиден формат {pattern}"),
		minLength:       mkMsgFn('Минимум {len} символа'),
		maxLength:       mkMsgFn('Максимум {len} символа'),
		minNum:          mkMsgFn('Минимум {min}'),
		maxNum:          mkMsgFn('Максимум {max}'),
		minString:       mkMsgFn('Минимум {min}'),
		maxString:       mkMsgFn('Максимум {max}'),
		minCount:        mkMsgFn('Минимум {min} опции'),
		maxCount:        mkMsgFn('Максимум {max} опции'),
		passwordLength:  mkMsgFn('Дължина минимум {length} символа'),
		passwordRepeat:  mkMsgFn('Максимум брой повтарящи се символи {repeat}'),
		passwordLcase:   mkMsgFn('Минимум {req} малки букви'),
		passwordUcase:   mkMsgFn('Минимум {req} главни букви'),
		passwordNum:     mkMsgFn('Минимум {req} цифри'),
		passwordSpecial: mkMsgFn('Минимум {req} специални символи (напр.: !?@#, и други)'),
	},

	required(msg) {
		if(!msg) msg = this.messages.required();
		return v => !_.isEmpty(v) || msg;
	},

	// Type Validators

	email(msg){
		if(!msg) msg = this.messages.email();
		return v => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || msg;
	},

	/**
	 * @param int Decimals before dot
	 * @param dec Decimals after dot
	 * @param msg
	 * @return {function(*=)}
	 */
	decimal(int, dec, msg){
		if(!msg) msg = this.messages.decimal({int,dec});

		const pattern = new RegExp("^[+-]?\\d{0,"+int+"}(\\.\\d{0,"+dec+"})?$");

		return v => !v || pattern.test(v) || msg;
	},

	/**
	 * Generic numeric validator
	 * @param msg
	 */
	numeric(msg){
		const pattern = /^-?\d*\.?\d*$/;
		if(!msg) msg = this.messages.pattern({pattern});

		return v => !v || pattern.test(v) || msg;
	},

	//Generic Validators

	pattern(pattern, msg){
		pattern = new RegExp(pattern);
		if(!msg) msg = this.messages.pattern({pattern});

		return v => !v || pattern.test(v) || msg;
	},

	minLength(len, msg) {
		if(!msg) msg = this.messages.minLength({len});

		len = parseInt(len);
		return v => !v || v.length >= len || msg;
	},

	maxLength(len, msg) {
		if(!msg) msg = this.messages.maxLength({len});

		len = parseInt(len);
		return v => !v || v.length <= len || msg;
	},

	min(min, msg) {
		if(!msg) msg = this.messages.minNum({min});
		min = parseFloat(min);
		return v => !v || parseFloat(v) >= min || msg;
	},

	max(max, msg) {
		if(!msg) msg = this.messages.maxNum({max});
		max = parseFloat(max);
		return v => !v || parseFloat(v) <= max || msg;
	},

	minString(min, msg) {
		if(!msg) msg = this.messages.minString({min});
		return v => !v || v >= min || msg;
	},

	maxString(max, msg) {
		if(!msg) msg = this.messages.maxString({max});
		return v => !v || v <= max || msg;
	},

	minCount(min, msg) {
		if(!msg) msg = this.messages.minCount({min});
		return v => !v || v.length >= min || msg;
	},

	maxCount(max, msg) {
		if(!msg) msg = this.messages.maxCount({max});
		return v => !v || v.length <= max || msg;
	},

	password(charClasses, minLength, maxRepeat) {
		const classRegex = {
			'1': '[a-z]',
			'2': '[A-Z]',
			'3': '\\d',
			'4': '[^a-zA-Z0-9 ]',
		};
		const classMsg = {
			'1': this.messages.passwordLcase,
			'2': this.messages.passwordUcase,
			'3': this.messages.passwordNum,
			'4': this.messages.passwordSpecial,
		};
		return v => {
			if (!v) return true;
			v = v.toString();

			if (minLength > 0 && v.length < minLength) {
				return this.messages.passwordLength({length: minLength});
			}

			if (maxRepeat > 0) {
				try {
					for (const m of v.match(/(\S)\1+/g)) {
						if (m.length > maxRepeat) {
							return this.messages.passwordRepeat({repeat: maxRepeat});
							// break;
						}
					}
				}
				catch (e) {
					// empty
				}
			}

			for (let cClass in charClasses) {
				cClass = cClass.toString();
				const req = charClasses[cClass];
				let count = 0;
				try {
					const regex = new RegExp(classRegex[cClass], 'g');
					count += v.match(regex)?.length ?? 0;
				}
				catch (e) {
					// empty
				}
				if (count < req) {
					return classMsg[cClass]({req});
				}
			}

			return true;
		};
	},

}