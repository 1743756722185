<!--
Input for decimal values.
Automatically replaces decimal comma with a dot.
-->

<template>
	<v-text-field
		v-bind="$attrs"
		@input="input"
		v-on="$listeners"
		@focusin="focusIn"
		@focusout="focusOut"
		@keydown.exact.esc="onEscape"
		:rules="rules"
	>
		<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"/>
		</template>
	</v-text-field>
</template>

<script>
import validators from "@/ittijs/Validators";

export default {
	data(){
		return {
			valOnFocus: null,
			rules: [
				...this.$attrs['rules'] || [],
				validators.numeric('Не е число!'),
			],
		}
	},
	methods: {
		input(val){
			if (typeof val === 'string' && -1 !== val.indexOf(',')) {
				val = val.replace(',', '.');
				this.$nextTick(()=>{
					this.$emit('input', val);
				});
			}
		},
		focusIn(){
			this.valOnFocus = this.$attrs['value'];
		},
		focusOut(){
			this.valOnFocus = null;
		},
		onEscape(){
			if (this.valOnFocus !== null) {
				this.$emit('input', this.valOnFocus);
			}
		},
	}
}
</script>